import TimeDisplay from '../TimeDisplay';
import { IoLogoLinkedin } from "react-icons/io"
import { IoLogoGithub } from "react-icons/io";
import { IoMail } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footer">
      <div className="time">
          <TimeDisplay />
      </div>
      <div className="contactLinks">
        <nav>
          <ul>
            <li><a href="https://github.com/aliqut" target="_blank"><IoLogoGithub /></a></li>
            <li><a href="https://www.linkedin.com/in/ali-q-2409ab2a2" target="_blank"><IoLogoLinkedin /></a></li>
            <li><a href="mailto:ali.quttaineh@outlook.com"><IoMail /></a></li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Footer;
