import { useEffect, useState } from 'react';

const TimeDisplay = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update time every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const options = { 
        timeZone: 'Europe/London', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit' 
    };
    const timeString = new Intl.DateTimeFormat('en-GB', options).format(currentTime);

    return <p>BST: {timeString}</p>;
};

export default TimeDisplay;

