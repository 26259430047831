import Project from '../Project';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import ProjectNav from '../ProjectNav';
import { motion } from 'framer-motion';

const projects = [
  {
    title: '3x osc | eurorack module',
    description: 'open source eurorack format voltage-controlled oscillator (VCO) synth module that i designed and built for my own modular synth. the schematics and printed circuit-boards were designed using kicad.',
    image: '/3xosc.png',
    link: 'https://github.com/aliqut/3xosc',
    linkText: 'visit github repo',
  },
  {
    title: '',
    description: 'writing documentation... more to come',
    image: '',
    link: '',
    linkText: '',
  },
];

const Works = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = useCallback((event) => {
    event.preventDefault();
    if (event.deltaY > 0 && currentIndex < projects.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else if (event.deltaY < 0 && currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentIndex, projects.length]);

  // touch event handling
  useEffect(() => {
    const container = containerRef.current;
    let touchStartY = 0;
    let touchEndY = 0;

    const handleTouchStart = (event) => {
      touchStartY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      touchEndY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      if (touchStartY - touchEndY > 50 && currentIndex < projects.length - 1) {
        // swipe up
        event.preventDefault();
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else if (touchEndY - touchStartY > 50 && currentIndex > 0) {
        // swipe down
        event.preventDefault();
        setCurrentIndex((prevIndex) => prevIndex - 1);
      }
    };

    if (container) {
      container.addEventListener('touchstart', handleTouchStart, { passive: true });
      container.addEventListener('touchmove', handleTouchMove, { passive: true });
      container.addEventListener('touchend', handleTouchEnd, { passive: false });
      container.addEventListener('wheel', handleScroll, { passive: false });

      return () => {
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchend', handleTouchEnd);
        container.removeEventListener('wheel', handleScroll);
      };
    }
  }, [handleScroll, currentIndex, projects.length]);

  const handleNavigate = (index) => {
    setCurrentIndex(index);
  };

  return (
    <motion.div 
      className="works" 
      ref={containerRef}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <ProjectNav currentIndex={currentIndex} total={projects.length} onNavigate={handleNavigate} />
      <div className="project-container" style={{ transform: `translateY(-${currentIndex * 100}vh)` }}>
        {projects.map((project, index) => (
          <div key={index} className="project-wrapper">
            <Project {...project} />
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Works;
