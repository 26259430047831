import { motion } from 'framer-motion';

const About = () => {
  return (
    <motion.div className="about"

      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="About-title">
        <h1>who am i</h1>
        <div className="About-Text">
          <p>
          i enjoy coding, engineering, designing and building things. examples of projects i have worked on include linux software, analogue synthesisers and websites. <br />
          i am passionate about all things tech, always learning and exploring new things. 
          aside from tech, i love music production, photography, and art. <br />
          <br />
          you can find information about my education and work experience on my CV.
          </p>
          <div></div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
