import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import AnimatedRoutes from './components/AnimatedRoutes';

function App() {
  return (
    <div className="Site">
      <div className="Frame">
        <Header />
        <AnimatedRoutes />
        <Footer />
      </div>
    </div>
  );
}

export default App;
