import React from 'react';

const ProjectNav = ({ currentIndex, total, onNavigate }) => {
  const circles = Array.from({ length: total }, (_, index) => (
    <div
      key={index}
      className={`circle ${index === currentIndex ? 'active' : ''}`}
      onClick={() => onNavigate(index)}
    ></div>
  ));

  return (
    <div className="project-nav">
      <button
        className={`up-arrow ${currentIndex > 0 ? 'visible' : ''}`}
        onClick={() => onNavigate(currentIndex - 1)}
        disabled={currentIndex <= 0}
      >
        ↑
      </button>
      <div className="circles">
        {circles}
      </div>
      <button
        className={`down-arrow ${currentIndex < total - 1 ? 'visible' : ''}`}
        onClick={() => onNavigate(currentIndex + 1)}
        disabled={currentIndex >= total - 1}
      >
        ↓
      </button>
    </div>
  );
};

export default ProjectNav;
