import { LiaDownloadSolid } from "react-icons/lia";
import { IoMailOutline } from "react-icons/io5";
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

const CURSOR_CLASS_NAME = 'type';

const Home = () => {
  return (
    <motion.div
      className="home"
      
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="Name-title">
        <h1>
          <TypeAnimation
            cursor={false}
            className={CURSOR_CLASS_NAME}
            sequence={[
              'ali quttaineh',
              750,
              (el) => el.classList.remove(CURSOR_CLASS_NAME),
            ]}
            speed={50}
          />
        </h1>
        <p>
          <TypeAnimation
            cursor={false}
            className={CURSOR_CLASS_NAME}
            sequence={[
            (el) => el.classList.remove(CURSOR_CLASS_NAME),
            1100,
            (el) => el.classList.add(CURSOR_CLASS_NAME),
            400,
            'electrical engineer ',
            550,
            'electrical engineer | artist ',
            550,
            'electrical engineer | artist | developer',
            ]}
            speed={50}
          />
        </p>
        <div className="Title-links">
          <a href="mailto:ali.quttaineh@outlook.com"><IoMailOutline />e-mail me</a>
          <a href="/Ali%20Quttaineh%20-%20Resume.pdf" target="_blank" className="download-cv"><LiaDownloadSolid />download CV</a>
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
