import { Link } from 'react-router-dom';

const Header = () => {
  return(
    <div className="header">
      <nav>
        <ul>
            <li><Link class="homeNavLink" to="/">~</Link></li>
            <li><Link to="/about">about</Link></li>
            <li><Link to="/works">works</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
